import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import {useFormik} from 'formik'
import * as Yup from "yup";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getExpertDetail, setExpertDetail } from '../../../utils/expertsApi';
import { format } from 'date-fns';
import Feedback from '../../../components/Feedback';
import { getCountries } from '../../../utils/api';
import { Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { getUsers } from '../../../utils/usersApi';
import { handleFileUpload } from '../../../utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo obbligatorio"),
  surname: Yup.string().required("Campo obbligatorio"),
  country_id: Yup.number().required("Campo obbligatorio"),
  user_id: Yup.number().required("Campo obbligatorio"),
});

const Input = styled('input')({
  display: 'none',
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const defaultState = {
  country_id: '',
  current_professional_position: "",
  date_of_birth: "",
  description: "",
  education: "",
  email: "",
  email_alternative: "",
  image: "",
  job_title: "",
  languages: "",
  name: "",
  professional_qualification: "",
  // others: "",
  phone: "",
  practical_experience: "",
  previous_position: "",
  surname: "",
  website: null,
  user_id: "",
  short_publication: "",
  academic_position: "",
  curriculum: null,
  published: false,
}

export default function ExpertDetail() {
    const [countries, setCountries] = useState([])
    const [users, setUsers] = useState([])

    const [showSnackBar, setShowSnackBar] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState("")
    const { id } = useParams();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: defaultState,
      validationSchema: validationSchema,
      onSubmit:(values) => { 
        save(values)
      }
    });

    const save = async (values) => {
      const expertDetail = {...values}
      if (values.date_of_birth) expertDetail.date_of_birth = format(new Date(values.date_of_birth), 'yyyy-MM-dd')
      const resExpert = await setExpertDetail(expertDetail)
      if (resExpert) {
        setFeedbackMessage("Esperto Salvato")
        setShowSnackBar(true)
      } else {
        setFeedbackMessage("Qualcosa è andato storto")
        setShowSnackBar(true)
      }
    }

    const fileChange = async (e, field) => {
      handleFileUpload(e, (res) => formik.setFieldValue(field, res))
    }


    useEffect(() => {
      async function fetchData() {
        const resCountries = await getCountries()
        if (resCountries) {
          setCountries(resCountries)
        }
        const resUsers = await getUsers()
        if (resUsers) {
          setUsers(resUsers)
        }
        
        if (id) {
          const res = await getExpertDetail(id)
          if (res) {
            formik.setValues(res)
          }
        }
      }

      fetchData(id)
    }, []);

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={4}>
      <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.published ? true : false}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "published",
                          !formik.values.published
                        );
                      }}
                      inputProps={{
                        name: "published",
                        "aria-label": "controlled",
                      }}
                    />
                  }
                  label="Pubblica"
                />
              </Item>
              <Item>
              <img src={formik.values.image && `${process.env.REACT_APP_API_HOST}/api/image/${formik.values.image}`} alt="" width="100" />
              <label htmlFor={`contained-button-image`}>
                  <Input accept="image/*" id={`contained-button-image`} type="file"  onChange={(event) => fileChange(event, 'image')}/>
                  <Button variant="contained" component="span">
                    Upload Immagine
                  </Button>
                </label>
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="user-label">Utente</InputLabel>
                  <Select
                    labelId="user-label"
                    id="user"
                    name="user_id"
                    value={formik.values.user_id}
                    label="Utente"
                    onChange={formik.handleChange}
                    error={formik.errors.user_id}
                    helperText={formik.errors.user_id}
                  >
                    {users.sort((a, b) => a.surname.toLowerCase() > b.surname.toLowerCase() ? 1:-1).map(user => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                  </Select>
                </FormControl>
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country_id"
                    value={formik.values.country_id}
                    label="Country"
                    onChange={formik.handleChange}
                    error={formik.errors.country_id}
                    helperText={formik.errors.country_id}
                  >
                    {countries.map(country => <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Item>
              <Item>
                  <TextField
                    name="name"
                    fullWidth
                    id="name"
                    label={"Nome"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name}
                    helperText={formik.errors.name}
                  />
              </Item>
              <Item>
                  <TextField
                    name="surname"
                    fullWidth
                    id="surname"
                    label={"Cognome"}
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    error={formik.errors.surname}
                    helperText={formik.errors.surname}
                  />
              </Item>
              <Item>
                  <TextField
                    name="job_title"
                    fullWidth
                    id="job_title"
                    label={"Job Title"}
                    value={formik.values.job_title}
                    onChange={formik.handleChange}
                    error={formik.errors.job_title}
                    helperText={formik.errors.job_title}
                    multiline
                  />
              </Item>
              <Item>
                  <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.date_of_birth}
                    onChange={(value) => formik.setFieldValue('date_of_birth', value)}
                    renderInput={(params) => <TextField {...params} error={formik.errors.date_of_birth} helperText={formik.errors.date_of_birth} />}
                    error={formik.errors.date_of_birth}
                  />
              </Item>
              <Item>
                  <TextField
                    name="education"
                    fullWidth
                    id="education"
                    label={"Education"}
                    value={formik.values.education}
                    onChange={formik.handleChange}
                    error={formik.errors.education}
                    helperText={formik.errors.education}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="professional_qualification"
                    fullWidth
                    id="professional_qualification"
                    label={"Professional qualification"}
                    value={formik.values.professional_qualification}
                    onChange={formik.handleChange}
                    error={formik.errors.professional_qualification}
                    helperText={formik.errors.professional_qualification}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="academic_position"
                    fullWidth
                    id="academic_position"
                    label={"Academic position"}
                    value={formik.values.academic_position}
                    onChange={formik.handleChange}
                    error={formik.errors.academic_position}
                    helperText={formik.errors.academic_position}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="description"
                    fullWidth
                    id="description"
                    label={"Memberships and functions"}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.errors.description}
                    helperText={formik.errors.description}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="current_professional_position"
                    fullWidth
                    id="current_professional_position"
                    label={"Current professional position"}
                    value={formik.values.current_professional_position}
                    onChange={formik.handleChange}
                    error={formik.errors.current_professional_position}
                    helperText={formik.errors.current_professional_position}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="previous_position"
                    fullWidth
                    id="previous_position"
                    label={"Previous position"}
                    value={formik.values.previous_position}
                    onChange={formik.handleChange}
                    error={formik.errors.previous_position}
                    helperText={formik.errors.previous_position}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="practical_experience"
                    fullWidth
                    id="practical_experience"
                    label={"Practical Experience"}
                    value={formik.values.practical_experience}
                    onChange={formik.handleChange}
                    error={formik.errors.practical_experience}
                    helperText={formik.errors.practical_experience}
                    multiline
                  />
              </Item>
              <Item>
                  <TextField
                    name="email_alternative"
                    fullWidth
                    id="email_alternative"
                    label={"Email"}
                    value={formik.values.email_alternative}
                    onChange={formik.handleChange}
                    error={formik.errors.email_alternative}
                    helperText={formik.errors.email_alternative}
                  />
              </Item>
              <Item>
                  <TextField
                    name="phone"
                    fullWidth
                    id="phone"
                    label={"Phone"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.errors.phone}
                    helperText={formik.errors.phone}
                  />
              </Item>
              <Item>
                  <TextField
                    name="website"
                    fullWidth
                    id="website"
                    label={"Website"}
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    error={formik.errors.website}
                    helperText={formik.errors.website}
                  />
              </Item>
              <Item>
                  <TextField
                    name="languages"
                    fullWidth
                    id="languages"
                    label={"Languages"}
                    value={formik.values.languages}
                    onChange={formik.handleChange}
                    error={formik.errors.languages}
                    helperText={formik.errors.languages}
                  />
              </Item>
              <Item>
                  <TextField
                    name="short_publication"
                    fullWidth
                    id="short_publication"
                    label={"Short publication"}
                    value={formik.values.short_publication}
                    onChange={formik.handleChange}
                    error={formik.errors.short_publication}
                    helperText={formik.errors.short_publication}
                    multiline
                  />
              </Item>
              <Item>
              <Chip label={formik.values.curriculum}/>
              <label htmlFor={`contained-button-curriculum`}>
                  <Input accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" id={`contained-button-curriculum`} type="file"  onChange={(event) => fileChange(event, 'curriculum')}/>
                  <Button variant="contained" component="span">
                    Upload Curriculum
                  </Button>
                </label>
              </Item>
              <Item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.published ? true : false}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "published",
                          !formik.values.published
                        );
                      }}
                      inputProps={{
                        name: "published",
                        "aria-label": "controlled",
                      }}
                    />
                  }
                  label="Pubblica"
                />
              </Item>
            </Grid>
          </Grid>
      </div>
      <Button variant='contained' type="submit" onClick={formik.handleSubmit}>Save</Button>
    </Stack>
  </LocalizationProvider>
  <Feedback
    open={showSnackBar}
    handleClose={() => setShowSnackBar(false)}
    message={feedbackMessage}
  />
  </>
}
